import React from 'react'
import { View } from '../../components/View'
import { Text } from '../../components/Text'
import { Logo } from '../../Logo'
import { Link } from '../../components/Link'
import { useParams } from 'react-router-dom'
import { getCategory } from './examples'
import styled from 'styled-components'
import { useTheme } from '../../providers/ThemeProvider'

const Container = styled(View)`
  text-decoration: none;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const HeaderText = styled(Text)`
  font-size: 26px;
  line-height: 18px;
  text-decoration: none;
  text-transform: capitalize;
  color: ${({ theme }) => theme.text};
  `

const Which = styled(HeaderText)`
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.pink};
  text-transform: uppercase;
  font-family: 'Fjalla One', sans-serif;
`
export function ElectionTitle ({ value, ...props }) {
  const { category } = useParams()
  const { Icon, name } = getCategory(category)
  const { text } = useTheme()

  return (
    <Container as={Link} to='/' py={3}>
      <Icon fill={text} width={48} height={48} />
      <View ml={3}>
        <Which>WHICH</Which>
        <HeaderText>{name}</HeaderText>
      </View>
    </Container>
  )
}
ElectionTitle.propTypes = { }
