
import { ReactComponent as Appetizer } from '../../assets/icons/appetizer.svg'
import { ReactComponent as Vacation } from '../../assets/icons/airport.svg'
import { ReactComponent as Movie } from '../../assets/icons/movie.svg'
import { ReactComponent as Recipe } from '../../assets/icons/recipe.svg'
import { ReactComponent as Restaurant } from '../../assets/icons/restaurant.svg'
import { ReactComponent as Thing } from '../../assets/icons/thing.svg'

export const categories = [
  { Icon: Restaurant, name: 'restaurant', examples: ['McDonald\'s', 'The Cheesecake Factory', 'Dominos', 'Texas Roadhouse', 'Burger King', 'Taco Bell', 'In-N-Out'] },
  { Icon: Appetizer, name: 'appetizer', examples: ['Chicken Wings', 'Crab Cakes', 'Onion Dip', 'Spinach Artichoke Dip', 'Sliders'] },
  { Icon: Movie, name: 'movie', examples: ['The Dark Knight', 'The Godfather', 'Pulp Fiction', 'The LEGO Movie', 'Airplane!', '2001: A Space Odyssey'] },
  { Icon: Recipe, name: 'recipe', examples: ['Mashed Potatoes and Gravy', 'Stir-fry', 'Steaks', 'Hamburger Helper', 'Enchiladas', 'Tacos', 'Fettuccine Alfredo'] },
  { Icon: Vacation, name: 'vacation', examples: ['Hawaii', 'Disneyland', 'Italy', 'France', 'Colorado', 'New York City'] },
  { Icon: Thing, name: 'thing', examples: ['thing', 'stuff', 'crap', 'garbage', 'iono', 'idk', 'some thing'] }
]

const map = Object.fromEntries(
  categories.map(category => [category.name, category])
)

export function getCategory (id) {
  return map[id] || map.thing
}

export const examples = Object
  .fromEntries(
    categories
      .map(({ name, examples }) => [name, examples])
  )
