import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import { Home } from './routes/Home'
import { BeginElection } from './routes/BeginElection'
import { SelectCategory } from './routes/SelectCategory'
import { FullHeight } from './components/FullHeight'
import { Decision } from './routes/Decision'
import { ShareCode } from './routes/ShareCode'
import { Vote } from './routes/Decision/Vote'

window.addEventListener('resize', () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})

function App () {
  return (
    <Router>
      <FullHeight maxWidth={600} mx='auto'>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/which/:category' component={BeginElection} />
          <Route path='/which' component={SelectCategory} />
          <Route path='/decision/:id/vote' component={Vote} />
          <Route path='/decision/:id/code' component={ShareCode} />
          <Route path='/decision/:id' exact component={Decision} />
          <Redirect from='/e/:id' to='/decision/:id' />
        </Switch>
      </FullHeight>
    </Router>
  )
}

export default App
