import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { View } from './components/View'

const Container = styled(View)`
  text-decoration: none;
  width: ${({ size }) => size * 1.3}px;
  padding: ${({ size }) => size * 0.1}px ${({ size }) => size * 0.25}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Line = styled.div`
  height: ${({ size }) => size * 0.06}px;
  width: 100%;
  background-color: ${({ theme, color }) => theme.colors[color]};
  margin-bottom: ${({ size }) => size * 0.02}px;
`

const TopBun = styled(Line)`
  border-radius: ${({ size }) => size * 0.1}px ${({ size }) => size * 0.1}px 0 0;
  height: ${({ size }) => size * 0.13}px;
`

const BottomBun = styled(Line)`
  border-radius: 0 0 ${({ size }) => size * 0.1}px ${({ size }) => size * 0.1}px;
  height: ${({ size }) => size * 0.13}px;
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.pink};
  font-size: ${({ size }) => size * 0.4}px;
  line-height: ${({ size }) => size * 0.4}px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Fjalla One', sans-serif;
`

export function Logo ({ size = 60, minimal, ...props }) {
  if (minimal) {
    return (
      <Container size={size} {...props}>
        <Text size={size}>Which</Text>
      </Container>
    )
  }
  return (
    <Container size={size} {...props}>
      <TopBun size={size} color='brown' />
      <Line size={size} color='yellow' />
      <Text size={size}>Which</Text>
      <Line size={size} color='green' />
      <BottomBun size={size} color='brown' />
    </Container>
  )
}
