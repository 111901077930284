import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { View } from './components/View'
import { Text } from './components/Text'
import { ReactComponent as Drag } from './assets/icons/drag.svg'

const DragHandle = sortableHandle(({ value }) => {
  return (
    <View px={2} height={50} flexDirection='row' alignItems='center' justifyContent='center'>
      <Drag />
      <Text ml={2}>{value}</Text>
    </View>
  )
})

const SortableItem = sortableElement(({ value: { name }, active, ...stuff }) => {
  if (active) console.log('value', name)
  return (
    <View flexDirection='row' pl={1} bg='white' borderTop='1px solid rgba(0,0,0,.125)' alignItems='center'>
      <DragHandle value={name} />
    </View>
  )
})

const SortableContainer = sortableContainer(({ children, ...other }) => {
  console.log('other', other)
  return <View bg='#f0f0f0' borderBottom='1px solid rgba(0,0,0,.125)'>{children}</View>
})

export function Sortable ({ list = [], onChange }) {
  const [activeIdx, setActiveIdx] = useState(-1)
  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    onChange(items => arrayMove(items, oldIndex, newIndex))
    setActiveIdx(-1)
  }, [])
  return (
    <SortableContainer
      lockAxis='y'
      onSortStart={({ index }) => {
        setActiveIdx(index)
      }}
      onSortEnd={onSortEnd}
    >
      {list.map((value, index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value} active={index === activeIdx} />
      ))}
    </SortableContainer>
  )
}

Sortable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string)
}
