import React from 'react'
import PropTypes from 'prop-types'
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider as Provider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'

const httpLink = createHttpLink({
  uri: '/graphql'
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = window.localStorage.getItem('_fp')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const client = new ApolloClient({
  link: authLink
    .concat(createPersistedQueryLink({ useGETForHashedQueries: true }))
    .concat(httpLink),
  cache: new InMemoryCache()
})

export function ApolloProvider ({ children }) {
  return (
    <Provider client={client}>
      {children}
    </Provider>
  )
}
ApolloProvider.propTypes = {
  children: PropTypes.node
}
