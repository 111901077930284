import React from 'react'
import { View } from './View'
import { Text } from './Text'
import { Link } from './Link'
import { getCategory } from '../routes/BeginElection/examples'
import styled from 'styled-components'
import { useTheme } from '../providers/ThemeProvider'
import { ReactComponent as Back } from '../assets/icons/back.svg'
import { useHistory } from 'react-router-dom'

const Container = styled(View)`
  text-decoration: none;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const Button = styled(View)`
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
`

const Center = styled(View)`
  text-decoration: none;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`

const HeaderText = styled(Text)`
  font-size: 26px;
  line-height: 18px;
  text-decoration: none;
  text-transform: capitalize;
  color: ${({ theme }) => theme.text};
  `

const Which = styled(HeaderText)`
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.pink};
  text-transform: uppercase;
  font-family: 'Fjalla One', sans-serif;
`
export function Header ({ category }) {
  const { Icon, name } = getCategory(category)
  const { text } = useTheme()
  const history = useHistory()

  return (
    <Container as={Link} to='/' py={3}>
      <Button onClick={() => history.goBack()}>
        <Back />
      </Button>
      <Center>
        <Icon fill={text} width={48} height={48} />
        <View ml={3}>
          <Which>WHICH</Which>
          <HeaderText>{name}</HeaderText>
        </View>
      </Center>
      <Button />
    </Container>
  )
}
Header.propTypes = { }
