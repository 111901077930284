import styled from 'styled-components/macro'
import { typography, color, space, compose } from 'styled-system'

export const Text = styled.span(
  compose(
    typography,
    space,
    color
  )
)
