import React from 'react'
import PropTypes from 'prop-types'
import { Text } from './Text'
import { Link as RouterLink } from 'react-router-dom'

export const Link = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Text as={RouterLink} ref={ref} {...props}>{children}</Text>
  )
})

Link.propTypes = {
  children: PropTypes.node
}
