import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { View } from './View'

const Container = styled(View)`
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
  min-height: -webkit-fill-available;
`

export function FullHeight ({ children, ...props }) {
  useEffect(() => {
    function handler () {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])
  return (
    <Container {...props}>
      {children}
    </Container>
  )
}
