import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Fingerprint2 from 'fingerprintjs2'

const idle = (timeout = 250) => new Promise(resolve => window.requestIdleCallback
  ? window.requestIdleCallback(resolve)
  : setTimeout(resolve, timeout)
)

async function get (options = {}) {
  await idle()
  const components = await Fingerprint2.getPromise(options)
  return Fingerprint2.x64hash128(
    components.map(({ value }) => value).join(''),
    31
  )
}

const FingerprintContext = createContext(Promise.resolve(null))

export function FingerprintProvider ({ children }) {
  const [fingerprint, setFingerprint] = useState(window.localStorage.getItem('_fp'))
  useEffect(() => {
    if (fingerprint) return
    get()
      .then(value => {
        setFingerprint(value)
        window.localStorage.setItem('_fp', value)
      })
      .catch(console.error)
  }, [fingerprint])
  return (
    <FingerprintContext.Provider value={fingerprint}>
      {children}
    </FingerprintContext.Provider>
  )
}
export function useFingerprint () {
  return useContext(FingerprintContext)
}
FingerprintProvider.propTypes = { children: PropTypes.node }
