import React, { useMemo } from 'react'
// import PropTypes from 'prop-types'
import jdenticon from 'jdenticon'
import { View } from './View'
import styled from 'styled-components'
import { useTheme } from '../providers/ThemeProvider'
import { parseToHsl } from 'polished'

const Wrapper = styled(View)`
  background-color: #fff;
`

export function Identicon ({ value = 'dumbthing', size = 50 }) {
  const theme = useTheme()
  const str = useMemo(() => {
    const hues = Object
      .values(theme.colors)
      .map(color => parseToHsl(color).hue)
      .map(Math.round)
      .sort()
    return jdenticon.toSvg(value, size, { hues })
  }, [value, size])
  return (
    <Wrapper boxShadow='medium' size={size} dangerouslySetInnerHTML={{ __html: str }} />
  )
}
Identicon.propTypes = { }
