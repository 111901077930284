import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import { gql, useMutation } from '@apollo/client'

import { ElectionTitle } from './ElectionTitle'
import { RegisterCandidates } from './RegisterCandidates'
import { View } from '../../components/View'
import { useHistory, useParams } from 'react-router-dom'

const Form = styled(View)`
  flex: 1;
  justify-content: space-between;
`

const BeginElectionMutation = gql`
  mutation BeginElection($input: BeginElectionInput!) {
    beginElection(input: $input) {
      clientMutationId
      ...on BeginElectionSuccess {
        election {
          id
          candidates {
            id
            name
          }
        }
      }
      ...on BeginElectionFailure {
        error {
          message
        }
      }
    }
  }
`

export function BeginElection () {
  const { category } = useParams()
  const [state, setState] = useState({
    name: category,
    candidates: [
      { id: 'a', name: 'A' },
      { id: 'b', name: 'B' },
      { id: 'c', name: 'C' }
    ],
    seats: 1
  })
  const history = useHistory()

  const [beginElection] = useMutation(BeginElectionMutation)
  const handleSubmit = async (e) => {
    e.preventDefault()
    const results = await beginElection({ variables: { input: state } })
    history.replace(`/decision/${results.data.beginElection.election.id}`)
  }

  const valid = state.candidates.length > 2

  const setValues = (values) => setState(state => ({ ...state, ...values }))

  return (
    <Form as='form' onSubmit={handleSubmit} alignSelf='center' width='100%'>
      <ElectionTitle value={state.name} onChange={(name) => setState(state => ({ ...state, name }))} />
      <View p={3}>
        <RegisterCandidates value={state} onSubmit={setValues} category={state.name} />
        <button disabled={!valid} type='submit' className={`btn btn-block btn-lg btn-${valid ? 'primary' : 'secondary'}`}>{valid ? 'Let\'s Go' : 'Need at least 3 options'}</button>
      </View>
    </Form>
  )
}
BeginElection.propTypes = {}
