import React, { useMemo } from 'react'
// import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components/macro'
import { useTheme } from '../providers/ThemeProvider'

const Container = styled.div`
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
`

const stretchdelay = keyframes`
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
`

const Bar = styled.div`
  background-color: ${({ color }) => color || '#ccc'};
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right: 1px;

  animation: ${stretchdelay} 1.2s infinite ease-in-out;
  animation-delay: -${({ index }) => 1.2 - (index * 0.1)}s;
`

export function Loading () {
  const theme = useTheme()
  const colors = useMemo(() => Object.values(theme.colors), [theme])

  return (
    <Container>
      <Bar index={0} color={colors[0]} />
      <Bar index={1} color={colors[1]} />
      <Bar index={2} color={colors[2]} />
      <Bar index={3} color={colors[3]} />
      <Bar index={4} color={colors[4]} />
    </Container>
  )
}
Loading.propTypes = {}
