import React from 'react'
// import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'

import { View } from '../../components/View'
import { Text } from '../../components/Text'
import { ShareCode, useCopy, useShare } from '../../components/ShareCode'
import { Identicon } from '../../components/Identicon'
import { Header } from '../../components/Header'
import { ReactComponent as Copy } from '../../assets/icons/copy.svg'
import { ReactComponent as Share } from '../../assets/icons/share.svg'
import { ReactComponent as QRCode } from '../../assets/icons/qr-code.svg'
import { Link } from '../../components/Link'
import { FullHeight } from '../../components/FullHeight'
import { Loading } from '../../components/Loading'
import { useUser } from '../../plugins/authentication'
import { useTheme } from '../../providers/ThemeProvider'

const ElectionDetailsQuery = gql`
  query ElectionDetailsQuery($id: ID!) {
    node(id: $id) {
      ...on Election {
        id
        name
        seats
        candidates {
          id
          name
        }
        hasVoted
        voterConnection {
          voters {
            id
            name
          }
          total
        }
        results {
          winners {
            id
            name
          }
        }
      }
    }
  }
`

const ElectionCloseMutation = gql`
  mutation CloseElection($input: CloseElectionInput!) {
    closeElection(input: $input) {
      clientMutationId
      ...on CloseElectionSuccess {
        election {
          id
          candidates {
            id
            name
          }
          seats
          results {
            winners {
              id
              name
            }
          }
        }
      }
      ...on CloseElectionFailure {
        error {
          message
        }
      }
    }
  }
`

function CopyTab ({ url }) {
  const [ref, copied] = useCopy({ value: url })
  const theme = useTheme()
  return (
    <View as={Link} ref={ref} style={{ textDecoration: 'none' }} alignItems='center' width='50%'>
      <View width={75} height={75} alignItems='center' justifyContent='center'>
        <Copy fill={copied ? theme.success : theme.text} width={50} height={50} />
      </View>
      <Text className='text-muted' as='small'>{copied ? 'Copied' : 'Copy Link'}</Text>
    </View>
  )
}

function ShareTab ({ url, text, title }) {
  const [ref] = useCopy({ value: url })
  const [share, canShare] = useShare({ url, text, title })
  if (!canShare) return <CopyTab url={url} />
  return (
    <View as={Link} ref={ref} onClick={share} style={{ textDecoration: 'none' }} alignItems='center' width='50%'>
      <View width={75} height={75} alignItems='center' justifyContent='center'>
        <Share width={50} height={50} />
      </View>
      <Text className='text-muted' as='small'>Share Link</Text>
    </View>
  )
}

export function ElectionDetails () {
  const user = useUser()
  const { id } = useParams()
  const { data, loading } = useQuery(ElectionDetailsQuery, { variables: { id } })
  const [closeElection] = useMutation(ElectionCloseMutation, { variables: { input: { id } } })

  if (loading) {
    return (
      <FullHeight style={{ flex: 1 }} alignItems='center' justifyContent='center'>
        <Loading />
      </FullHeight>
    )
  }

  async function onClose () {
    await closeElection()
  }

  return (
    <View justifyContent='space-between' alignItems='center' alignSelf='center' height='100%' width='100%' style={{ flex: 1 }}>
      <Header category={data?.node.name} />
      <View p={3} width='100%' alignItems='center'>
        <Text as='h5'>Gather stacks</Text>
        <View flexDirection='row' width='100%' alignItems='center'>
          <View as={Link} style={{ textDecoration: 'none' }} to={`/decision/${data.node.id}/code`} alignItems='center' width='50%'>
            <QRCode width={75} height={75} />
            <Text className='text-muted' as='small'>Scan Code</Text>
          </View>
          <View bg='#ccc' width='2px' height='100px' />
          <ShareTab url={`${window.location.origin}/e/${data.node.id}`} title={`Which ${data.node.name}?`} text={`${user?.displayName} wants your opinion`} />
        </View>
        {
          !data.node.hasVoted && (
            <View pt={3} width={215}>
              <View as={Link} to={`/decision/${data.node.id}/vote`} alignItems='center' className='btn btn-outline-primary btn-block btn'>Stack your options!</View>
            </View>
          )
        }
      </View>
      {
        data.node.voterConnection.voters.length > 0 && (
          <View px={3} width='100%' my={3}>
            <Text textAlign='center' as='small' style={{ textTransform: 'uppercase' }} color='gray'>Stacks submitted</Text>
            <View className='list-group'>
              {data?.node.voterConnection?.voters.map(({ id, name }) => (
                <View key={id} className='list-group-item' p={0} flexDirection='row' alignItems='center'>
                  <Identicon value={name} />
                  <Text ml={2}>{name}</Text>
                </View>
              ))}
            </View>
          </View>
        )
      }
      {
        data.node.hasVoted
          ? <View as='button' alignItems='center' onClick={onClose} className='btn btn-primary btn-block btn-lg'>Calculate results</View>
          : <View />
      }
    </View>
  )
}
ElectionDetails.propTypes = { }
