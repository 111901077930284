import React from 'react'
import { unstable_createRoot as createRoot } from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import 'bootstrap/dist/css/bootstrap.min.css'
import { ThemeProvider } from './providers/ThemeProvider'
import { ApolloProvider } from './providers/ApolloProvider'
import { FingerprintProvider } from './providers/FingerprintProvider'

createRoot(
  document.getElementById('root')
).render(
  // <React.StrictMode>
  <FingerprintProvider>
    <ApolloProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </ApolloProvider>
  </FingerprintProvider>
  // </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
