import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { Sortable } from '../../Sortable'
import { View } from '../../components/View'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'
// import fingerprint from 'fingerprintjs2'
import { useFingerprint } from '../../providers/FingerprintProvider'
import { Header } from '../../components/Header'
import { Text } from '../../components/Text'
import { FullHeight } from '../../components/FullHeight'
import { Loading } from '../../components/Loading'
import { useUser } from '../../plugins/authentication'

const GetBallotQuery = gql`
  query GetBallot($id: ID!) {
    node(id: $id) {
      id
      ...on Election {
        name
        hasVoted
        candidates {
          id
          name
        }
        results {
          winners {
            id
            name
          }
        }
        seats
      }
    }
  }
`

const CastVoteMutation = gql`
  mutation CastVoteMutation($input: CastVoteInput!) {
    castVote(input: $input) {
      clientMutationId
      ...on CastVoteSuccess {
        vote {
          id
          ballot {
            id
            name
          }
          voter {
            id
            name
          }
        }
        election {
          id
          name
          hasVoted
          candidates {
            id
            name
          }
        }
      }
      ...on CastVoteFailure {
        error {
          message
        }
      }
    }
  }
`

export function Vote () {
  const { id } = useParams()
  const { data, loading, error } = useQuery(GetBallotQuery, { variables: { id } })
  const [castVote] = useMutation(CastVoteMutation)
  const fingerprint = useFingerprint()
  const [ballot, setBallot] = useState([])
  const history = useHistory()
  const user = useUser()

  useEffect(() => {
    if (loading) return
    setBallot(data.node.candidates)
  }, [data, loading])

  if (loading) {
    return (
      <FullHeight style={{ flex: 1 }}>
        <Loading />
      </FullHeight>
    )
  }

  return (
    <View
      width='100%'
      alignSelf='center'
      as='form'
      justifyContent='space-between'
      flex='1'
      onSubmit={async (e) => {
        e.preventDefault()
        await castVote({
          variables: {
            input: {
              name: user?.displayName,
              ballot: ballot.map(({ id }) => id),
              electionId: id,
              voterId: fingerprint
            }
          }
        })
        history.push(`/decision/${id}`)
      }}
    >
      <View>
        <Header name={data.node.name} />
        <Text as='small' textAlign='center' my={3} className='text-muted text-uppercase'>Best</Text>
        <Sortable list={ballot} onChange={setBallot} />
        <Text as='small' textAlign='center' my={3} className='text-muted text-uppercase'>Worst</Text>
      </View>

      <View m={3} alignItems='center' as='button' type='submit' className='btn btn-primary btn-lg'>I'm done</View>
    </View>
  )
}
Vote.propTypes = { }
