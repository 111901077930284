import React from 'react'
// import PropTypes from 'prop-types'
import { View } from '../../components/View'
import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Vote } from './Vote'
import { ElectionDetails } from './ElectionDetails'
import { Results } from './Results'
import { Loading } from '../../components/Loading'
import { FullHeight } from '../../components/FullHeight'

const GetBallotQuery = gql`
  query GetBallot($id: ID!) {
    node(id: $id) {
      id
      ...on Election {
        name
        hasVoted
        candidates {
          id
          name
        }
        results {
          winners {
            id
            name
          }
        }
        seats
      }
    }
  }
`

export function Decision () {
  const { id } = useParams()
  const { data, loading, error } = useQuery(GetBallotQuery, { variables: { id } })

  if (loading) {
    return (
      <FullHeight style={{ flex: 1 }} alignItems='center' justifyContent='center'>
        <Loading />
      </FullHeight>
    )
  }
  if (error) return <View>Error</View>

  if (data.node.results.winners.length) return <Results election={data.node} />
  return <ElectionDetails election={data.node} />
}
Decision.propTypes = { }
