import React from 'react'
import { Link } from '../components/Link'
import { FullHeight } from '../components/FullHeight'
import { Logo } from '../Logo'
import { View } from '../components/View'
import { SignIn, useUser } from '../plugins/authentication'
import { Loading } from '../components/Loading'
import { Text } from '../components/Text'
import { Identicon } from '../components/Identicon'

// import PropTypes from 'prop-types'

export function Home () {
  const user = useUser()
  return (
    <FullHeight alignItems='center' justifyContent='center' style={{ flex: 1 }}>
      <View alignItems='center' mb={5}>
        <Logo size={200} as={Link} to='/' />
      </View>
      <View style={{ transition: 'height 1s' }}>
        {
          typeof user === 'undefined'
            ? <Loading />
            : user
              ? (
                <View>
                  <View alignItems='center' flexDirection='row' my={3}>
                    <Identicon size={75} value={user.displayName} />
                    <View>
                      <Text as='small'>Signed in as</Text>
                      <Text as='h4' mb={0}>{user.displayName}</Text>
                    </View>
                  </View>
                  <Link to='/which' className='btn btn-primary btn-lg'>Start Deciding</Link>
                </View>
              )
              : <SignIn />
        }
      </View>
    </FullHeight>
  )
}
Home.propTypes = {}
