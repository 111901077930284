import React, { useState, useRef, useEffect } from 'react'
// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app'

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics'

// Add the Firebase products that you want to use
import 'firebase/auth'
import 'firebase/firestore'
import 'firebaseui/dist/firebaseui.css'
import * as firebaseui from 'firebaseui'
import { useHistory } from 'react-router-dom'

const firebaseConfig = {
  apiKey: 'AIzaSyD7_SOTT939msHqqs-DK0bZmKuBJqUvNvA',
  authDomain: 'getwhich-app.firebaseapp.com',
  databaseURL: 'https://getwhich-app.firebaseio.com',
  projectId: 'getwhich-app',
  storageBucket: 'getwhich-app.appspot.com',
  messagingSenderId: '207291224536',
  appId: '1:207291224536:web:8c8b7b0033c6f4fe7022ad',
  measurementId: 'G-RTL4ZGWKM8'
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

firebase.analytics()

export function useUser () {
  const [user, setUser] = useState()
  useEffect(() => {
    firebase.auth().onAuthStateChanged(setUser)
  }, [])
  return user
}

export function SignIn () {
  const ref = useRef()
  const history = useHistory()
  useEffect(() => {
    var provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
    const ui = new firebaseui.auth.AuthUI(firebase.auth())
    ui.start(ref.current, {
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          console.log('authResult', authResult, redirectUrl)
          history.push('/which')
          return false
        },
        uiShown: () => {
          // The widget is rendered.
          // Hide the loader.
          // document.getElementById('loader').style.display = 'none'
        }
      },
      // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
      signInFlow: 'popup',
      signInSuccessUrl: '/which',
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.PhoneAuthProvider.PROVIDER_ID
      ],
      // Terms of service url.
      tosUrl: 'terms',
      // Privacy policy url.
      privacyPolicyUrl: 'privacy'
    })
  }, [])
  return <div ref={ref} />
}
