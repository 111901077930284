import React from 'react'
import { View } from '../components/View'
import { Text } from '../components/Text'
import { Logo } from '../Logo'
import { categories } from './BeginElection/examples'
import { Link } from '../components/Link'

export function SelectCategory () {
  return (
    <View alignItems='center' justifyContent='space-between' style={{ flex: 1 }} width='100%' alignSelf='center'>
      <Logo as={Link} to='/' size={130} />
      <View className='list-group' style={{ width: '100%' }}>
        {categories.map(({ Icon, name }) => {
          return (
            <View as={Link} to={`/which/${name}`} className='list-group-item list-group-item-action' key={name} alignItems='center' flexDirection='row' style={{ width: '100%' }}>
              <Icon width={50} height={50} /><Text ml={3} style={{ textTransform: 'capitalize', fontSize: 24 }}>{name}</Text>
            </View>
          )
        })}
      </View>
    </View>
  )
}
SelectCategory.propTypes = { }
