import React, { useState, useEffect } from 'react'
import Confetti from 'react-dom-confetti'
// import PropTypes from 'prop-types'

import { View } from '../../components/View'
import { Text } from '../../components/Text'
import { Header } from '../../components/Header'

const config = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 300,
  dragFriction: 0.12,
  duration: 10000,
  stagger: 3,
  width: '10px',
  height: '10px',
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
}

export function Results ({ election }) {
  const [ready, setReady] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true)
    }, 500)
    return () => clearTimeout(timer)
  }, [])
  const winners = election.results.winners
  return (
    <View alignItems='center' justifyContent='space-between' height='100%' style={{ flex: 1 }}>
      <Header name={election.name} />
      <View alignItems='center' zIndex={-1}>
        <View bg='#fff' px={3} py={2} borderRadius={6} style={{ zIndex: 9999 }}>
          {
            winners.lenght > 1
              ? winners.map(winner => <Text key={winner.id}>{winner.name}</Text>)
              : <Text textAlign='center' as='h1'>{winners[0].name}</Text>
          }
        </View>
        <Confetti active={ready} config={config} />
      </View>
      <View />
    </View>
  )
}
Results.propTypes = { }
