import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import QRCode from 'qrcode.react'
import { View } from '../components/View'
import { FullHeight } from '../components/FullHeight'
import { Text } from '../components/Text'
import { Header } from '../components/Header'
import { useQuery, gql } from '@apollo/client'

const ElectionTypeQuery = gql`
  query ElectionDetailsQuery($id: ID!) {
    node(id: $id) {
      ...on Election {
        id
        name
        seats
        candidates {
          id
          name
        }
        results {
          winners {
            id
            name
          }
        }
      }
    }
  }
`

export function ShareCode (props) {
  const params = useParams()
  const id = props.id || params.id
  const url = useMemo(() => `${window.location.origin}/e/${id}`, [params, id])
  const { data } = useQuery(ElectionTypeQuery, { variables: { id } })

  return (
    <FullHeight height='100%' style={{ flex: 1 }} alignItems='center' justifyContent='space-between'>
      <Header category={data.node.name} />
      <View py={3}>
        <View pb={4}>
          <QRCode value={url} size={256} />
        </View>
        <Text style={{ textTransform: 'uppercase' }} as='small' className='text-muted'>Scan this code to add your stack</Text>
      </View>
      <View />
    </FullHeight>
  )
}
ShareCode.propTypes = {
  id: PropTypes.string
}
