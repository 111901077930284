import styled from 'styled-components/macro'
import { space, color, layout, flexbox, grid, border, background, shadow, compose } from 'styled-system'

const fn = compose(
  space,
  color,
  layout,
  flexbox,
  grid,
  border,
  background,
  shadow
)
export const View = styled.div`
  display: flex;
  ${fn}
`

View.defaultProps = {
  flexDirection: 'column'
}
