import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { View } from '../../components/View'
import { TransitionGroup, Transition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import { Text } from '../../components/Text'
import { examples } from './examples'
import { ReactComponent as Trash } from '../../assets/icons/delete.svg'
import { useParams } from 'react-router-dom'
import { lighten } from 'polished'
import { useTheme } from '../../providers/ThemeProvider'

const Option = styled(View)`
  transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);

  ${({ state, last }) =>
    state === 'entering'
      ? css`
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        opacity: 0;
        transform: rotateX(-90deg);
        margin-bottom: 0;
      `
      : css`
        height: 42px;
        opacity: 1;
        transform: none;
        margin-bottom: ${last ? '1rem' : 0};
      `
  }
`

export function RegisterCandidates ({ value, onSubmit }) {
  const { category } = useParams()
  const theme = useTheme()
  const ref = useRef()
  const [candidate, setCandidate] = useState('')
  const [candidates, setCandidates] = useState(value.candidates || [])
  const example = useMemo(() => {
    const taken = candidates.map(value => value.name.toLowerCase())
    const list = (examples[category] || []).filter(value => !taken.includes(value.toLowerCase()))
    return list[Math.floor(Math.random() * list.length)]
  }, [category, candidates])

  useEffect(() => {
    onSubmit({
      candidates: candidates.map(({ name }) => ({ name }))
    })
  }, [candidates])

  const addCandidate = useCallback((candidate) => {
    ref.current.focus()
    if (!candidate) return
    const exists = candidates.some(({ name }) => name.toLowerCase() === candidate.toLowerCase())
    if (!exists) setCandidates([...candidates, { id: uuid(), name: candidate }])
    setCandidate('')
  }, [candidates])

  const removeCandidate = useCallback((index) => {
    ref.current.focus()
    setCandidates(candidates.slice(0, index).concat(candidates.slice(index + 1)))
  }, [candidates])

  console.log('candidates', candidates)
  return (
    <View
      py={3}
      alignItems='center'
      width='100%'
      height='100%'
      style={{ flex: 1 }}
      justifyContent='space-between'
    >

      {!candidates.length && <Text as='h3'>Let's add some options</Text>}
      <View width='100%'>
        <View style={{ perspective: '500px' }} width='100%'>
          <TransitionGroup className='list-group' appear enter exit>
            {
              candidates.map(({ id, name }, idx) => (
                <Transition key={id} timeout={0}>
                  {state => (
                    <Option
                      state={state}
                      className='list-group-item'
                      justifyContent='space-between'
                      flexDirection='row'
                      last={idx === candidates.length - 1}
                    >
                      {name}
                      <View onClick={() => removeCandidate(idx)}>
                        <Trash fill={lighten(0.5, theme.text)} />
                      </View>
                    </Option>
                  )}
                </Transition>
              ))
            }
          </TransitionGroup>
        </View>
        <View mb={3}>
          <div class='input-group mb-3'>
            <input
              type='text'
              autoFocus
              ref={ref}
              className='form-control form-control-lg'
              value={candidate}
              onChange={e => setCandidate(e.target.value)}
              placeholder={example && `e.g. ${example}`}
              onKeyPress={event => {
                if (event.key !== 'Enter') return
                event.preventDefault()
                addCandidate(candidate)
              }}
            />
            <div class='input-group-append'>
              <button onClick={() => addCandidate(candidate)} disabled={!candidate.length} class='btn btn-secondary btn-lg' type='button'>Add</button>
            </div>
          </div>
          <small className='text-muted'>Press return to add an option</small>
        </View>
      </View>
    </View>
  )
}
RegisterCandidates.propTypes = {
  candidates: PropTypes.arrayOf(
    PropTypes.string
  ),
  onChange: PropTypes.func.isRequired
}
