import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ThemeContext, ThemeProvider as Provider, createGlobalStyle } from 'styled-components'
// import { tint } from 'polished'

const colors = {
  pink: '#e374ab',
  green: '#6dc381',
  brown: '#d6a206',
  yellow: '#ffd556',
  black: '#333'
  // pink: '#D47FAC',
  // green: '#79CB83',
  // brown: '#B3864C',
  // yellow: '#D8C735'
}

// const lightColors = {
//   pink: tint(0.1, colors.pink),
//   green: tint(0.1, colors.green),
//   brown: tint(0.1, colors.brown),
//   yellow: tint(0.1, colors.yellow)
// }

const theme = {
  colors: colors,
  action: colors.pink,
  success: colors.green,
  text: colors.black,
  gutter: '1rem',
  halfGutter: '0.5rem'
}

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh, 1vh) * 100);
    min-height: -webkit-fill-available;
  }
`

export const ThemeProvider = ({ children }) => {
  return (
    <Provider theme={theme}>
      <GlobalStyle />
      {children}
    </Provider>
  )
}

export function useTheme () {
  return useContext(ThemeContext)
}

ThemeProvider.propTypes = {
  children: PropTypes.node
}
