import React, { useCallback, useMemo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'
import { View } from './View'
import Clipboard from 'clipboard'

export function useShare ({ title, text, url }) {
  const canShare = !!navigator.share
  const share = useCallback(async () => {
    if (!canShare) return
    await navigator.share({ title, text, url })
  }, [title, text, url])
  return [share, canShare]
}

export function useCopy ({ value }) {
  const [copied, setCopied] = useState(null)
  const ref = useRef()

  useEffect(() => {
    console.log('time to copy')
    if (!ref.current) return
    console.log('ooh its copyin')
    const clipboard = new Clipboard(ref.current, {
      text: () => value
    })
    clipboard.on('success', () => setCopied(true))
    clipboard.on('error', () => setCopied(false))
    return () => clipboard.destroy()
  }, [value])

  return [ref, copied]
}

function ShareButton ({ url, election }) {
  const [copied, setCopied] = useState(null)
  const ref = useRef()
  const canShare = useMemo(() => !!navigator.share, [])
  console.log('ref', ref)
  async function share () {
    if (!canShare) return
    await navigator.share({
      title: 'Which',
      text: 'Erika O\'Neal wants your opinion',
      url
    })
  }

  useEffect(() => {
    if (!ref.current) return
    console.log('clipboard created')
    const clipboard = new Clipboard(ref.current, {
      text: () => url
    })
    clipboard.on('success', () => setCopied(true))
    clipboard.on('error', () => setCopied(false))
    return () => clipboard.destroy()
  }, [url])

  return (
    <View ref={ref} as='button' alignItems='center' mt={2} className={`btn btn-${copied ? 'success' : 'secondary'}`} onClick={share}>
      {canShare ? 'Share' : copied ? 'Copied!' : 'Copy Link'}
    </View>
  )
}

export function ShareCode ({ election }) {
  const url = useMemo(
    () => `${window.location.origin}/e/${election.id}`,
    [election]
  )
  return (
    <View>
      <QRCode value={url} />
      <ShareButton url={url} election={election} />
    </View>
  )
}
ShareCode.propTypes = {
  electionId: PropTypes.string.isRequired
}
